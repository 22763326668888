/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Code Highlight */
@import "../node_modules/prismjs/themes/prism-coy.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";



:root {
  --bc-primary-font: 'IBM Plex Sans Thai';
  --bc-primary-font-family: var(--bc-primary-font), sans-serif;
}


$color-orange: #ff8e20;
body{
  background-color: white;
  font-family: var(--bc-primary-font-family) !important;
}
.button-signin {
  font-family: var(--bc-primary-font-family) !important;
  background-color:$color-orange;
  &:hover {
    background-color:$color-orange !important;
  }
}

button{
  font-family: var(--bc-primary-font-family) !important;
}

.san-thai{
  font-family: var(--bc-primary-font-family) !important;
}


.button-buy{
  font-family: var(--bc-primary-font-family) !important;
  background-color:#343434;
  padding: 3px !important;
  color: white;
  &:hover {
    background-color:#343434 !important;
  }
}

.button-signup {
  font-family: var(--bc-primary-font-family) !important;
  background-color: transparent;
  color:$color-orange;
  border-color:$color-orange;
  &:hover {
    background-color: transparent !important;
    color:$color-orange !important;
    border-color:$color-orange !important;
  }
}
.link-forgotpassword{
  color: $color-orange;
  &:hover{
    color: $color-orange !important;
  }
}

span.p-dropdown-label{
  font-family: var(--bc-primary-font-family) !important;
}

input{
  font-family: var(--bc-primary-font-family) !important;
}


.p-menubar-root-list{
  font-family: var(--bc-primary-font-family) !important;
}

ul{
  font-family: var(--bc-primary-font-family) !important; 
}

a{
  cursor: pointer;
}