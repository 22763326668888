@import './_variables';
@import "./_mixins";
@import "./_preloading";
@import "./_main";
@import "./_topbar";
@import "./_menu";
@import "./_config";
@import "./_content";
@import "./_footer";
@import "./_responsive";
@import "./_utils";
@import "./_typography";

/////////// dashboard
/// 
div.dashboard-container {
    height: calc(100vh - 110px);
    padding-bottom: 0;
    padding-right: 10px;
    padding-top: 2rem;
    display: block;
}

.page-title {
    display: flex;
    justify-content: space-between;
}

hr {
    border-top: solid #D7DBDD ;
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

div#dashboard-content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 50px;
}

/////////////////////
/////////// table

table .pi-sort-alt::before {
    content: '';
}

table .pi-sort-amount-down::before {
    content: '\e930';
}

table .pi-sort-amount-up-alt::before {
    content: '\e933';
}

.p-datatable .p-datatable-thead > tr > th {
    border: none;
    background: #F4F4F4;
}

.p-datatable .p-datatable-thead > tr > th:hover {
    border: none;
    background: #F4F4F4;
    color: black;
}

.p-datatable .p-datatable-thead > tr > th:hover i.p-sortable-column-icon {
    color: black;
}

.p-datatable .p-sortable-column i.p-sortable-column-icon {
    color: black;
}

.p-datatable .p-sortable-column i.p-sortable-column-icon:hover {
    color: black;
}

.p-datatable .p-sortable-column.p-highlight i.p-sortable-column-icon {
    color: black;
}

.p-datatable .p-sortable-column.p-highlight i.p-sortable-column-icon:hover {
    color: black;
}

.p-datatable .p-datatable-thead > tr > th.p-highlight {
    background: #E8E8E8;
    color: black;
}

.p-datatable .p-datatable-thead > tr > th.p-highlight:hover {
    background: #F0F0F0;
    color: black;
}

.p-datatable .p-datatable-thead > tr > th.p-highlight:hover i.p-sortable-column-icon {
    color: black;
}

.p-datatable .p-datatable-thead > tr > th.p-highlight:focus {
    background: #F0F0F0;
    color: black;
    box-shadow: none;
    outline: 0 none;
}

.p-datatable .p-datatable-thead > tr > th:active {
    box-shadow: none;
    outline: 0 none;
}

.p-datatable-thead th:first-of-type {
    border-radius: 10px 0 0 10px;
}

.p-datatable-thead th:last-of-type {
    border-radius: 0 10px 10px 0;
}

.p-paginator .p-paginator-current {
    display: none;
}

.p-datatable div.p-paginator {
    justify-content: start;
}

.p-datatable div.p-paginator p-dropdown {
    margin-left: auto;
}

.p-datatable div.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.5rem;
    height: 2.5rem;
}

.p-datatable div.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: black;
    border-color: black;
    color: white;
}


.package-plan-card .p-card .p-card-body {
    padding: 1rem;
    border-left: 3px solid #77AEB6 ;
    border-right: 3px solid #77AEB6 ;
    border-bottom: 3px solid #77AEB6  ;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

}

.package-plan-card .p-card{
    box-shadow: none;
    border: none;
}

.select-plan .p-card .p-card-body  {
    padding: 1rem;
    border-left: 3px solid black;
    border-right: 3px solid black;
    border-bottom: 3px solid black;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.payment-detail-card .p-card .p-card-body {
    padding: 1rem;
    border: 1px solid #D7DBDD ;
    border-radius: 5px;

}

.payment-detail-card .p-card .p-card-content{
    padding: 10px 20px;
}

.change-lang-login .p-dropdown{
    background-color: #0A205A;
    color: #FFF;
}

.change-lang-login .p-dropdown-label{
    color: #FFF;
}

.change-lang-login .p-dropdown-trigger .p-dropdown-trigger-icon{
    color: #FFF;
}

.tob-bar-info .p-menubar {
    padding: 0.5rem;
    background: #FFF;
    color: #495057;
    border: none;
    border-radius: 6px;
    font-weight: bold;
}

.switch-status .p-inputswitch-checked .p-inputswitch-slider{
    background: #ff8e20 ;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider{
    background: #ff8e20 !important;
}

.menubar-email{
    position: absolute;
    bottom: -9px;
    left: 24px;
}

.menubar-info{
    position: absolute;
    right: 17rem;
    bottom: 19px;
}

// rejister plan responsive
.col-plan{
    flex: 0 0 auto;
    padding: 1rem;
    width: 19.5%;
}


@media (min-width: 100px){
    .col-plan{
        flex: 0 0 auto;
        padding: 1rem;
        width: 100%;
    }
}


@media (min-width: 600px){
    .col-plan{
        flex: 0 0 auto;
        padding: 1rem;
        width: 32%;
    }
}

@media (min-width: 1200px){
    .col-plan{
        flex: 0 0 auto;
        padding: 1rem;
        width: 24.5%;
    }
}

@media (min-width: 1600px){
    .col-plan{
        flex: 0 0 auto;
        padding: 1rem;
        width: 19.5%;
    }
}






