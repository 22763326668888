.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin-left $transitionDuration;
    margin-left: 300px;
    margin-top: 100px;
    padding: 4rem;
}

.layout-main-container {
    display: flex;
    flex-direction: column;
    padding: 6rem 6rem 2rem 4rem;
    transition: margin-left $transitionDuration;
    overflow-y: hidden;
}

.layout-main {
    flex: 1 1 auto;
    margin-top: 4rem;
}

.button-signin{
    background-color: #708090;
    border: none;
}

.button-signin:hover{
    background-color: #000 !important;
    border: none;
}

.link-forgotpassword, .link-signup{
    color: #708090;
    font-size: 1.6rem;
    text-decoration: underline;
}

.link-forgotpassword:hover, .link-signup:hover{
    color: #000;
}

.p-inputtext:hover{
    border-color: #000 !important;
}

.p-inputtext:enabled:focus{
    border-color: #000 !important;
    box-shadow: none;
}

.price-container{
    font-size: 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    font-weight: bold;
    text-align: center;
}

.plan-title{
    margin: 0.5rem 0;
    text-align: center;
    font-weight: bold;
    height: 3rem;
    overflow: hidden;
}

.plan-card{
    cursor: pointer;
    margin-right: 1rem;
    background-color: #f8faff !important;
    position:relative;
    height: 37rem;
}


.plan-card:hover{
    border-color: #7e9ff8 !important;
    background-color: #eaeffd !important;
    .button-buy{
      background-color:#809ff8;
      &:hover {
        background-color:#809ff8 !important;
      }
    }
    .average-price{
      text-align: center;
      background-color: #ff8e20;
      color: white;
      border-radius: 10px;
    }
}

.bank-section .card{
    padding: 10px;
}

.plan-card:last-child{
  margin-right: 0;
  margin-bottom: 2rem !important;
}

.ribbon-recommend {
    --f: 12px; /* control the folded part*/
    --r: 0px; /* control the ribbon shape */
    --t: -10px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    clip-path: 
      polygon( 0 0,
              100% 0,
              100% calc(100% - var(--f)),
              calc(100% - var(--f)) 100%,
              calc(100% - var(--f)) calc(100% - var(--f)),
              0 calc(100% - var(--f)),
              var(--r) calc(50% - var(--f)/2));
    background: #ff8e20;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #E67E22 ;
    border-top-right-radius: 5px ;
    border-top-left-radius: 5px ;
    padding: 5px 5px 12px 7px; 
}
  
  .ribon-tag{
    margin-top: -5px;
  }
   
.price-label{
  font-size:1rem;
  color:#ff8e20;
}

.pincode-container{
    font-size: 7rem;
    width: fit-content;
    border: 1px solid #f2f4fe;
    padding: 2rem;
    border-radius: 15px;
    color: #000;
    background-color: #f2f4fe;
    letter-spacing: 1rem;
}

.main-container{
    width: 50%;
    margin-top: -2rem;
}

.dashboard-content{
    font-size: 7rem;
    margin-top: 7rem;
}

#signin_password .ng-trigger{
    display: none !important;
}

.stataus-tag{
    border-radius: 25px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
 }

 .status-green{
    background: #E0F8EB;
    color:  #3AB875;
 }

 .status-red{
    background: #FEE0D9;
    color:  #D63C18;
 }

 .status-yellow{
    background: #FFE49A;
    color:  #DBA001;
 }

.status-gray{
    background: #E0E0E0;
    color:   #6B6B6B;
 }

 .bg-green-card{
    background: #D3ECEF;
 }

 .text-card-header{
    color: #4F7479;
 }

 .bg-red-card{
    background: #D63C18;
 }

 .bg-yellow-card{
    background: #F5B041 ;
 }

 .card-header-radius{
    border-top-left-radius: 5px; 
    border-top-right-radius: 5px
 }

 .p-button-outlined-white {
    background-color: transparent;
    color: #FFF;
    border: 1px solid;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.p-button-outlined-white:hover {
    opacity: 0.5;
}

.selete-plan-payment{
    width: 100% !important;
}

.selete-plan-payment .p-dropdown{
    background: #D1E0FF;
    color: #FFF;
    width: 100%;
}

.change-lang-login .p-dropdown-label{
    color: #FFF;
}

.change-lang-login .p-dropdown-trigger .p-dropdown-trigger-icon{
    color: #FFF;
}

.strong-line{
    border:  solid 1px #343434; 
}

.plan-detail-div{
    height: 12rem;
    overflow: auto;
    ul {
        list-style: none;

        padding-inline-start : 0px;
       }
      
    ul li:before {
    content: '✓';
    background-color: #44d789 !important;
    color:white;
    z-index: 10;
    border-radius: 50%;
    padding: 1px 3px;
    font-size: 10px;
    margin-right: 7px;
    
    }
      
    ul li{
    padding: 5px 0px;
    }
}


.register-plan-detail-div{
    height: 20rem;
    overflow: auto;
    ul {
        list-style: none;

        padding-inline-start : 0px;
       }
      
    ul li:before {
    content: '✓';
    background-color: #44d789 !important;
    color:white;
    z-index: 10;
    border-radius: 50%;
    padding: 1px 3px;
    font-size: 10px;
    margin-right: 7px;
    
    }
      
    ul li{
    padding: 5px 0px;
    }
}


.p-button.p-button-secondary.p-button-no-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-no-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-no-outlined {
    background-color: transparent;
    color: black;
    border: none;
}

.p-button-no-outline {
    color: black;
    background-color: transparent;
    border: none;
    width: 100%;
    font-weight: bold;
    padding: 0.75rem 1.25rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    cursor: pointer;
}

.div-bank-group{
    height: 30vh; 
    overflow-y:auto; 
    overflow-x: hidden;
}

.payment-calendar span{
    width: 100%  !important;
}

.div-select-plan{
    position: absolute;
    bottom: 15px;
    right: 5%;
    width: 90%;
}
  
.button-edit{
    padding: 5px !important;
}

.search-fillter-gruop .p-dropdown{
    border-right: none;
    border-radius: 5px 0px 0px 5px;
}

.search-fillter-gruop .p-inputtext{
    border-left: none;
    border-radius: 0px;
    border-radius: 0px 5px 5px 0px;
}

.search-fillter-gruop  .p-inputtext:before {
    content : "";
    position: absolute;
    right   : 0;
    top     : 15%;
    height  : 70%;
    border-right:2px solid #A1A1A1;
}

.logo-credit{
    width: 5rem ;
    padding: 0px 10px 0px 0px ;
}

.qr-code-img img{
    width: 20rem !important;
}
